export const optionsList = [
  'Strongly Disagree',
  'Disagree',
  'Neutral',
  'Agree',
  'Strongly Agree',
];

export const questionsList = [
  'I have lots of experience with stock trading.',
  'My primary goal is to achieve high returns on my investments.',
  'I am less concerned with risk management and prioritize opportunities that offer substantial potential returns, even if they come with higher levels of risk.',
  'I will buy a specific stock with solely profits in mind regardless of understanding the business I am buying from.',
  'I am willing to accept elevated risk in pursuit of greater profits.',
  'Investing in the stock market is my primary source of income.',
  'My approach involves pursuing quick monetary gains and is centered around short-term trading rather than long-term holdings such as stocks or bonds.',
  'I often invest in companies solely focused on potential returns, rather than aligning my personal preferences with my investment decision making.',
  'Previously I invested in stocks that gave me my desired return in… 5 years+, under 2 years, within the year, monthly, daily',
  'My investments are typically geared towards achieving returns within a short timeframe, reflecting my preference for quick gains.',
];

export const packagesDesign = {
  btnClasses: ['btn-dark', 'btn-light', 'btn-warning'],
  highlightClasses: ['text-secondary', 'text-muted', 'text-warning'],
  packageClasses: ['box', 'box box-inverse box-info', 'box'],
};
export const PAGINATION_OPTIONS = {
  INITIAL_SKIP_COUNT: 0,
  INITIAL_PAGE_SIZE: 5,
  PAGE_SIZE_OPTIONS: [5, 10, 20, 50],
  INITIAL_PAGE_INDEX: 0,
  DEFAULT_SORT_FIELD: '_id',
  DEFAULT_SORT_ORDER: 'desc',
};

